import React, { useEffect, useState } from 'react';
import { useColorMode } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import Cookies from 'js-cookie';

import { useIntl } from 'context/IntlContext';
import { AmazonBanner } from 'components/AmazonBanner';
import TopCTA from '../../components/TopCTA';
import DigitalAccountSection from './DigitalAccountSection';
import WeBelieveFree from './WeBelieveFree';
import YouCanTrust from './YouCanTrust';
import Section from '../../components/Section';
import Seo from '../../components/SEO';
import FAQHome from '../../components/FAQHome';
import { Link } from 'gatsby';

const PAGE_COLOR_MODE = 'default';

const BannerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: calc(100% - 32px);
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background: transparent;
  padding: 0px;
  max-width: 1024px;
  width: 100%;
  text-align: center;
  position: relative;

  @media (max-width: 1024px) {
    max-width: calc(100% - 32px);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1001;
  border-radius: 100%;

  @media (max-width: 1024px) {
    right: 10px;
  }
`;

const Home = () => {
  const { t } = useIntl();
  const [colorMode, setColorMode] = useColorMode();
  const { locale } = useIntl();
  const isSpanishPage = locale === 'es';
  const isPortugalPage = locale === 'pt';
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
    const hasSeenBanner = Cookies.get('hasSeenBanner');
    if (!hasSeenBanner) {
      setPopupVisible(true);
    }
  }, [colorMode, setColorMode]);

  const href = isSpanishPage
    ? 'https://static.secureuserarea.com/promo/v3_TOP3_WTOP3_ES.pdf'
    : isPortugalPage
    ? 'https://static.secureuserarea.com/promo/v3_BIG6ONE_BIGJ6_PT.pdf'
    : '';

  const closePopup = () => {
    setPopupVisible(false); // Cerrar el pop-up
    Cookies.set('hasSeenBanner', 'true', { expires: 7 });  // Establecer la cookie para que no aparezca de nuevo en 7 días
  };

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      {(isSpanishPage || isPortugalPage) && isPopupVisible && (
        <PopupOverlay onClick={closePopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closePopup}>&times;</CloseButton>
            <BannerContainer>
              <AmazonBanner href={href} />
              <Link to={href} style={{ color: 'primary', marginRight: 2 }}></Link>
            </BannerContainer>
          </PopupContainer>
        </PopupOverlay>
      )}
      <TopCTA
        heading={t('homepage.home.hero.title')}
        subtitle={t('homepage.home.hero.subtitle')}
        image={
          <StaticImage src="../../images/hero-image.png" alt="Hero section image" />
        }
      />
      {/* <Marketplace /> */}
      <Section>
        <DigitalAccountSection />
      </Section>
      <Section>
        <WeBelieveFree />
      </Section>
      <Section>
        <YouCanTrust />
      </Section>
      <Section noDivider>
        <FAQHome />
      </Section>
    </>
  );
};

export default Home;
